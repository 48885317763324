import Vue from "vue";
import VueRouter from "vue-router";
import {getProjectCode} from "@/utils/store";

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, resolve, reject) {
    if (resolve || reject) return originalPush.call(this, location, resolve, reject)
    return originalPush.call(this, location).catch((e) => {
    })
}
const routes = [
    {
        path: "/",
        component: () => import("../components/Home.vue"),
        meta: {
            // title: "envirothon",
            title: "fbla",
            requiresAuth: false,
        },

    },
    {
        path: "/link",
        component: () => import("../components/Link.vue"),
        meta: {
            // title: "envirothon",
            title: "fbla",
            requiresAuth: false,
        },
    },
    {
        path: "/login",
        component: () => import("../components/LoginPage.vue"),
        meta: {
            title: "登录",
            requiresAuth: false,
        },
    },
    {
        path: "/projects",
        component: () => import("../components/ProjectList.vue"),
        meta: {
            title: "目录",
            requiresAuth: true,
        },
    },
    {
        path: "/detail",
        component: () => import("../components/ProjectDetail.vue"),
        meta: {
            title: "详情",
            requiresAuth: true,
        },
    },
    {
        path: "/test",
        component: () => import("../components/Test.vue"),
        meta: {
            title: "登录",
            requiresAuth: false,
        },
    }
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    if (to.path === '/login') {
        next()
    } else {
        let project_code = getProjectCode()
        if (to.meta.requiresAuth && (project_code == undefined || project_code == '')) {
            next({
                path: '/'
            })
        } else {
            next()
        }
    }
});

export default router;
