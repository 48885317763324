import Cookies from 'js-cookie'

const USER_ID = 'fbla-fwh-user_id'
const USER_TYPE = 'fbla-fwh-user_type'
const PROJECT_CODE = 'fbla-fwh-project_code'
const SIGN_CACHE = 'fbla-fwh-sign_cache'

// const USER_ID = 'en-fwh-user_id'
// const USER_TYPE = 'en-fwh-user_type'
// const PROJECT_CODE = 'en-fwh-project_code'
// const SIGN_CACHE = 'en-fwh-sign_cache'

export function setSignCache(cache) {
    Cookies.set(SIGN_CACHE, cache)
}

export function getSignCache() {

    return Cookies.get(SIGN_CACHE)
}

export function setUserId(user_id) {
    Cookies.set(USER_ID, user_id)
}

export function getUserId() {
    return Cookies.get(USER_ID)
}

export function setProjectCode(project_code) {
    Cookies.set(PROJECT_CODE, project_code)
}

export function getProjectCode() {
    return Cookies.get(PROJECT_CODE)
}

export function setUserType(user_type) {
    Cookies.set(USER_TYPE, user_type)
}

export function getUserType() {
    return Cookies.get(USER_TYPE)
}

export function clearUser() {
    Cookies.remove(USER_ID)
    Cookies.remove(USER_TYPE)
    Cookies.remove(PROJECT_CODE)
}
